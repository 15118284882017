<template>
  <div class="container_box">
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1002912')" prop="billNos">
              <Input type="textarea" :placeholder="$t('key1005945')" v-model.trim="pageParams.billNos"></Input>
            </Form-item>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <Form-item :label="$t('key1002882')">
              <Date-picker
                type="datetimerange"
                class="filter_time_box"
                @on-clear="resetDate"
                @on-change="getDateValue"
                :clearable="true"
                transfer
                format="yyyy-MM-dd HH:mm:ss"
                placement="bottom-end"
                :placeholder="$t('key1000273')"
                :value="billingTime">
              </Date-picker>
            </Form-item>
          </Col>
          <Col style="width:170px;" push="1">
            <Button @click="reset" v-once icon="md-refresh" class="mr10">{{ $t('key1000095') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000274') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <!--排序-->
      <div class="mr15 flex align-items-center">
        <span>{{ $t('key1003700') }}</span>
        <local-tabButton :tabButtonList="settlementStatusList" @selectStatus="settlementStatusBtn"></local-tabButton>
      </div>
      <!--导出账单-->
      <Dropdown @on-click="changeExportBtn" class="mr15" v-if="getPermission('ymsSupplierBill_billExport')">
        <Button type="primary">
          <span>{{ $t('key1002365') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002366') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002367') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        border
        max-height="560"
        :loading="tableLoading"
        :columns="tableColumns"
        @on-selection-change="tableChange"
        :data="tableData">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1005946')"
      paramKeyColums="supplierBillColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
  </div>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';
import {SUPPLIER_SETTLEMENTSTATUS} from "@/utils/enum";
import exportFieldsModal from "@/components/common/exportFieldsModal";
import {pageJump, amountHandel, setConfigCharacters} from "@/utils/common";

export default {
  name: 'reconciliationBill',
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      pageParamsStatus: false,
      billingTime: [],
      pageParams: {
        billNos: '',
        createdStartTime: null,
        createdEndTime: null,
        status: 2, // 1 待审核状态 2 待确认 3 等待收款/付款 4 已收款/已付款 5 作废
        pageNum: 1,
        pageSize: 15
      },
      total: 0,
      tableColumns: [],
      tableData: [],
      settlementStatusList: SUPPLIER_SETTLEMENTSTATUS,
      ymsBillId: null,
      selectTableData: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000646'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'supplierPackageCode', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000446')},
            {paramKey: 'supplierSpu', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000861')},
            {paramKey: 'supplierSku', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000498')},
            {paramKey: 'quantity', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002897')},
            {paramKey: 'thirtyVolumes', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002898')},
            {paramKey: 'suggestPrice', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003030')},
            {paramKey: 'schemePrice', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005949')},
            {paramKey: 'totalAmount', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003031')},
            {paramKey: 'supplierPickupOrderNumber', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002899')},
            {paramKey: 'boxedTime', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002900')},
            {paramKey: 'pickupTime', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002901')},
            {paramKey: 'shipedTime', paramValue: `${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003697')}`},
            {paramKey: 'createdTime', paramValue: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002855')},
          ]
        }
      ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.pageParamsStatus = true;
    },
    // 重置按钮
    reset() {
      let v = this;
      v.settlementStatusBtn(0, 1);
      v.$refs['pageParams'].resetFields();
      v.billingTime = [];
      v.resetDate();
      v.getList();
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      params.billNos = v.sepCommasFn(v.pageParams.billNos, [',', '，', '\n']);
      params.status = [params.status]
      return params;
    },
    // 列表查询的方法
    getList() {
      let v = this;
      v.tableData = [];
      v.total = 0;
      if (v.getPermission('ymsSupplierBill_query')) {
        v.tableLoading = true;
        v.axios.post(api.post_ymsSupplierBill_query, v.handleParameter()).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            v.tableData = data.list || [];
            v.total = data.total;
            v.updateTableDom('selection');
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 获取账单时间
    getDateValue(value) {
      let v = this;
      if (value.length === 0) {
        v.$Message.error(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000289'));
        v.resetDate();
      } else {
        if (!value[0]) {
          v.pageParams.createdStartTime = null;
          v.pageParams.createdEndTime = null;
        } else {
          let timeList = v.defaultTimePeriod(value);
          v.billingTime = v.defaultTimePeriod(value, false);
          v.pageParams.createdStartTime = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams.createdEndTime = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      }
    },
    // 重置账单时间
    resetDate() {
      this.pageParams.createdStartTime = null;
      this.pageParams.createdEndTime = null;
    },
    // 结算状态
    settlementStatusBtn(index, value) {
      let v = this;
      v.pageParams.status = value;
      v.settlementStatusList.forEach((n, i) => {
        n.selected = i === index;
      });
      v.optionBtnWidth = [];
      v.pageParamsStatus = true;
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = data.map((item) => {
        return item.ymsBillId
      });
    },

    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002368'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002369'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn() {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.ymsBillIds = v.selectTableData;
      } else {
        query.ymsBillIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((value) => {
        if (value) {
          v.axios.post(api.post_ymsSupplierBill_billExport, query,
            {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 确认账单按钮
    confirmBillBtn() {
      let v = this;
      v.axios.get(api.get_ymsPayAccount).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data && data.length > 0) {
            v.$Modal.confirm({
              title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001600'),
              content: `${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005947')}${setConfigCharacters}${aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005948')}`,
              okText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001600'),
              onOk: () => {
                v.axios.put(api.put_ymsSupplierBill_confirmBill + v.ymsBillId, {loading: true, loadingText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000213')}).then(response => {
                  if (response.data.code === 0) {
                    v.$store.commit('updatePendingCount', Date.now());
                    v.$Message.success(aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000413'));
                    v.pageParamsStatus = true;
                  }
                });
              },
              onCancel: () => {
              }
            });
          } else {
            v.$Modal.confirm({
              title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005950'),
              content: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005951'),
              okText: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005952'),
              onOk: () => {
                v.$router.push('/accountManage/collectionAccount');
              },
              onCancel: () => {
              }
            });
          }
        }
      });
    },
    // 处理列表字段
    handleColumns(value) {
      let v = this;
      v.optionBtnWidth = [];
      let columns = [
        {
          type: 'selection',
          width: 70,
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005953'),
          key: 'billNo',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            let ymsBillId = params.row.ymsBillId;
            return h('Alink', {
              props: {
                AlinkShow: v.getPermission('ymsSupplierBill_supplierBillDetail'),
                linkName: params.row.billNo,
                styles: {
                  justifyContent: 'center'
                }
              },
              on: {
                AlinkBtn: () => {
                  const {href} = v.$router.resolve({
                    path: '/reconciliationBillDetails',
                    query: {
                      ymsBillId: ymsBillId
                    }
                  });
                  window.open(href, '_blank');
                }
              }
            });
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003014'),
          key: 'price',
          align: 'center',
          minWidth: 130,
          render: (h, params) => {
            let price = params.row.totalPrice !== null ? amountHandel(params.row.totalPrice) : '';
            return h('div', [
              h('div', price),
              h('div', '(' + v.currencyState + ')')
            ]);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003016'),
          key: 'packageQuantity',
          minWidth: 130,
          align: 'center'
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003017'),
          key: 'settlementStatus',
          align: 'center',
          minWidth: 130,
          render: (h, params) => {
            let title = '';
            v.settlementStatusList.map((item) => {
              if (item.value === params.row.status) {
                title = item.title;
              }
            });
            return h('div', title);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002849'),
          key: 'billingCycle',
          align: 'center',
          width: 220,
          render: (h, params) => {
            let time = '';
            let startTime = params.row.periodStartTime;
            let endTime = params.row.periodEndTime;
            if (startTime && endTime) {
              let periodStartTime = v.$uDate.dealTime(startTime);
              let periodEndTime = v.$uDate.dealTime(endTime, 'fulltime');
              // 结束时间减一秒
              let handlerTime = new Date(periodEndTime);
              let newTime = new Date(periodEndTime);
              let timer = handlerTime.getTime();
              newTime.setTime(timer - 1000);
              let newEndTime = new Date(newTime)
              time = periodStartTime + ' - ' + v.$uDate.getDateTime(newEndTime);
            }
            return h('div', time);
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1002855'),
          key: 'createdTime',
          sortable: true,
          align: 'center',
          minWidth: 180,
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.createdTime, 'fulltime'));
          }
        },
        {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005954'),
          key: 'updatedTime',
          sortable: true,
          minWidth: 180,
          align: 'center',
          render: (h, params) => {
            return h('div', v.$uDate.dealTime(params.row.updatedTime, 'fulltime'));
          }
        }
      ];
      let options = {
        title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000283'),
        fixed: 'right',
        align: 'center',
        width: 130,
        render(h, params) {
          return h('optsButtonList', {
            props: {
              operationList: [
                {
                  text: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1001600'),
                  show: value === 2 && v.getPermission('ymsSupplierBillId_confirm'),
                  clickFn: () => {
                    v.ymsBillId = params.row.ymsBillId;
                    v.confirmBillBtn();
                  }
                }
              ]
            },
            on: {
              setWidth(value) {
                if (value) {
                  v.optionBtnWidth.push(value);
                  params.column.width = Math.max(...v.optionBtnWidth);
                  v.$forceUpdate();
                }
              }
            }
          });
        }
      };
      if (value === 4) {
        let obj = {
          title: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1003040'),
          key: 'alipayOrderId',
          minWidth: 150,
          align: 'center'
        };
        columns.splice(2, 0, obj);
      }
      if (value === 2) {
        columns.push(options);
      }
      v.tableColumns = columns;
      v.$forceUpdate();
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    'pageParams.status': {
      handler(value) {
        let v = this;
        v.handleColumns(value);
      },
      immediate: true,
      deep: true
    }
  },
  components: {
    exportFieldsModal
  }
};
</script>

<style scoped>

</style>
